.qr-container {
  position: relative;
}

.download-square {
  display: none;
  pointer-events: none;
  background: #3337;
  position: absolute;
  width: 256px;
  height: 256px;
  justify-content: center;
  align-items: center;
  left: 50%;
  margin-left: -128px;
  top: 0;
  font-size: 26px;
  color: #FFF;
  text-shadow: 2px 2px 2px #000;
}

#diagram:hover + .download-square {
  display: flex;
}
